'use client';

import { Button, Dropdown, DropdownItem, Heading, Icon } from 'daskapital-ds';

import { Language } from '@/lib/helpers/getI18n';
import Link from 'next/link';
import { useGlobalContext } from '@/lib/globalContext';
import { useState } from 'react';

const LanguageSwitcher = () => {
	const state = useGlobalContext();
	const [seletedLanguage, setSelectedLanguage] = useState(state?.lang);

	return (
		<>
			{state?.i18n.locales && (
				<Dropdown
					title={
						<Heading tag={'span'} size={'texts'} style={{ color: 'currentcolor' }}>
							{state?.i18n?.languages.filter((x: Language) => x.code === state.lang)[0].name}
						</Heading>
					}
					icon={<Icon icon="icon-chevron-down" />}
					style={{ verticalAlign: 'middle' }}>
					{state?.i18n?.locales.map((locale: string) => {
						return (
							<DropdownItem key={`lang-${locale}`}>
								<Link href={state.translatedUri && state.translatedUri[locale] ? state.translatedUri[locale] : `/${locale}/`}>
									<Heading tag="span" size="texts" className={state.lang === locale ? 'color-primary50' : ''}>
										{state?.i18n?.languages.filter((x: Language) => x.code === locale)[0].name}
									</Heading>
								</Link>
							</DropdownItem>
						);
					})}
				</Dropdown>
			)}
		</>
	);
};

export default LanguageSwitcher;
